














































































































import Vue, { VueConstructor } from 'vue';
import AdminVoucherService from '@/services/admin/AdminVoucherService';
import DataTable from '@/components/shared/DataTable.vue';
import { MetaInfo } from 'vue-meta';
import { validationMixin } from 'vuelidate';
import CreateCard from '@/components/forms/CreateCard.vue';
import ToastMessage from '@/models/ToastMessage';
import { maxValue, minValue, required } from 'vuelidate/lib/validators';
import FormaterMixin from '@/mixins/FormaterMixin.vue';
import ValidatedSelect from '@/components/forms/ValidatedSelect.vue';
import ValidatedInput from '@/components/forms/ValidatedInput.vue';

const validations = {
  voucher: {
    voucherScopeType: { required },
    voucherDiscountType: { required },
    initialAmount: {},
    percentage: {
      minValue: minValue(0),
      maxValue: maxValue(100),
    },
  },
};

class VoucherIndexDto {
  id!: string;
  code!: string;
  voucherScopeType = '';
  voucherDiscountType = '';
  percentage!: string;
  minimalOrderAmount!: string;
}

export default (Vue as VueConstructor<Vue & InstanceType<typeof FormaterMixin>>).extend({
  components: { DataTable, CreateCard, ValidatedSelect, ValidatedInput },
  metaInfo(): MetaInfo {
    return {
      title: this.$t('pages.admin.AdminVoucherIndex.meta.title').toString(),
    };
  },
  validations,
  mixins: [validationMixin, FormaterMixin],
  data() {
    let voucher = new VoucherIndexDto();
    return {
      vouchers: [] as VoucherIndexDto[],
      voucher: voucher,
      tableFilterValue: '',
      pages: 0,
      sorterValue: { column: 'createdAt', asc: false },
      currentPage: 1,
      loading: true,
      ready: false,
      message: '',
      availableVoucherScopeTypes: [] as string[],
      availableVoucherDiscountTypes: [] as string[],
      tableFilter: {
        external: false,
        lazy: false,
        label: this.$t('components.DataTable.table-filter-label'),
        placeholder: this.$t('components.DataTable.table-filter-placeholder'),
      },
    };
  },
  watch: {
    sorterValue: function () {
      this.loadData();
    },
    currentPage: function () {
      this.loadData();
    },
    tableFilterValue: function () {
      this.loadData();
    },
  },
  mounted() {
    this.loadData();
    this.setBreadCrumb();
  },
  computed: {
    availableVoucherScopeTypeOptions(): { value: string; label: string }[] {
      let options = this.availableVoucherScopeTypes.map((type) => {
        return { value: type, label: type };
      });
      options.sort((a, b) => a.label.localeCompare(b.label));
      options = [{ value: '', label: '' }, ...options];
      return options;
    },
    availableVoucherDiscountTypeOptions(): { value: string; label: string }[] {
      let options = this.availableVoucherDiscountTypes.map((type) => {
        return { value: type, label: type };
      });
      options.sort((a, b) => a.label.localeCompare(b.label));
      return options;
    },
  },
  methods: {
    setBreadCrumb() {
      this.$store.commit('UiStoreModule/setBreadcrumbItems', [
        { translationKey: 'pages.admin.AdminVoucherIndex.breadcrumb.last' },
      ]);
    },

    loadData() {
      this.loading = true;
      AdminVoucherService.getIndex(
        this.currentPage,
        this.sorterValue.column,
        this.sorterValue.asc,
        this.tableFilterValue
      ).then((res) => {
        this.vouchers = res.value.vouchers.items.map((m: VoucherIndexDto) => ({
          ...m,
        }));
        this.availableVoucherScopeTypes = res.value.availableVoucherScopeTypes;
        this.voucher.voucherScopeType = this.availableVoucherScopeTypes[0];
        this.availableVoucherDiscountTypes = res.value.availableVoucherDiscountTypes;
        this.voucher.voucherDiscountType = this.availableVoucherDiscountTypes[0];
        this.pages = res.value.vouchers.totalPages;
        this.setBreadCrumb();
        this.loading = false;
        this.ready = true;
      });
    },

    handleSubmit() {
      this.loading = true;
      return AdminVoucherService.createNew(this.voucher).then(
        (resp) => {
          this.$store.commit(
            'addToastMessage',
            new ToastMessage(this.$t('forms.common.save_successful').toString(), 'bg-success')
          );
          this.$router.push({
            name: 'AdminVoucherEdit',
            params: { voucherId: resp.value },
          });
        },
        (error) => {
          this.$store.commit(
            'addToastMessage',
            new ToastMessage(this.$t('forms.common.save_error').toString(), 'bg-warning')
          );
          this.loading = false;
          this.message = error;
        }
      );
    },
  },
});
